@import "./variables";

.tt-noticeDisplay {
  position: relative;
  top: -60px;
  border: 7px solid $whiteColor !important;
  box-shadow: 2px 3px 3px $shadowColor;
  border-radius: 10px !important;
  margin-top: 30px;
  transition: 0.8s;
  height: 288px;
  overflow: hidden;

  .tt-noticeSlider {
    width: 90% !important;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    height: 220px;
    font-size: 25px;
    // line-height: 14;
    text-align: center;
    transition: height 0.8s;

    &:focus {
      outline: none;
    }

    .tt-noticeDate {
      text-align: left;
      margin-top: 5px;
      margin-bottom: 9px;
    }

    .tt-noticeContent {
      text-align: left;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      /* number of lines to show */
      -webkit-box-orient: vertical;
      height: 68px;
      margin-bottom: 5px;

      p {
        margin-bottom: 0;
      }
    }

    .tt-noticeHeading {
      font-weight: bold;
      margin-bottom: 0;
      font-size: 21px;
      text-align: left;
    }

    .tt-noticeButton {
      position: relative;
      top: -10px;
      line-height: 1.5;
      right: 12px;
    }
  }
}

.tt-noticeButton {
  position: relative;
  top: -10px;
  line-height: 1.5;
  right: 12px;
}

.tt-noticesSubData {
  margin-top: 1px;
  padding-left: 25px;
  cursor: default;
  font-size: 19px;
  color: $whiteColor;
  text-shadow: 1px 1px 2px $borderColor;

  span {
    font-size: 12px;
    font-weight: normal;
    cursor: default;
  }
}

.tt-groupTargetList {
  display: block;
  text-align: left;
  line-height: 1;
  font-size: 13px;
  overflow-y: auto;
  height: 35px;

  div {
    display: inline-block;
    background-color: $primaryBtnColor;
    padding: 2px 20px;
    line-height: 1.5;
    color: $whiteColor;
    margin-right: 10px;
    border-radius: 10px;
    cursor: default;
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
  }
}

.tt-noticeFooterText {
  line-height: 2;
  text-align: left;
  /* text-shadow: none; */
  font-weight: 500;
  /* letter-spacing: 0px; */
  font-size: 13px;
}

//Notice Modal Body

.tt-attachedBox {
  background-color: $attachFileBoxColor;
  line-height: 4;
  border: 1px dashed $primaryColor;
  border-radius: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 250px;
}

.tt-noticeModalDate {
  font-size: 12px;
  margin-bottom: 0;
}

.hideGoToTop {
  display: none !important;
}
.tt-scrollButton {
  float: right;
  margin-bottom: 10px;
}

.tt-acknowledgeBox {
  padding-right: 6px;
  display: inline;
  font-size: 18px;
  position: relative;
  top: 2px;
}

.tt-acknowledgeBoxRed {
  color: $dangerBtnColor;
}

.tt-acknowledgeBoxGreen {
  color: $secondaryBtnColor;
}

.tt-noticeRSVP {
  border: 1px dashed $primaryColor;
  padding: 10px;
  margin: 10px;

  p {
    margin-bottom: 0;
  }
}

.tt-acknowledgeListBox {
  background-color: $primaryColor;
  text-align: center;
  border: 1px solid $primaryHoverColor;
  margin-top: 13px;
  max-height: 460px;
  overflow: hidden;
  padding: 2px;
  transition: 0.5s;
}

.tt-ackPeopleList {
  font-size: 12px;
  margin: 0 0 3px 2px;
  text-align: left;
  width: 98%;
  background: $whiteColor;

  .tt-noticeRemind {
    margin-bottom: 0;
    background: $primaryBtnColor;
    color: $whiteColor;
    font-size: 11px;
    padding: 2px 8px;
    cursor: pointer;
  }
}

.tt-noticeModal {
  margin: auto !important;
  height: 100%;
  max-height: -webkit-fill-available;

  .modal-footer {
    display: block;
    background-color: $primaryColor;
  }
}

#noticeModalBody {
  height: 79vh;
  overflow: auto;
}

@media screen and (min-width: 1400px) {
  #noticeModalBody {
    height: 81vh;
    overflow: auto;
  }
}

.tt-notice-link {
  display: inline-flex;
  overflow-x: auto;
  width: 100%;
  justify-content: center;
  height: 240px;

  iframe {
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  &::-webkit-scrollbar {
    height: 7px;
  }
}

.tt-notice-link-media {
  //   display: inline-flex;
  //   overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  iframe {
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  &::-webkit-scrollbar {
    height: 7px;
  }
}

.noticeFooter {
  width: 111%;
  margin-left: -55px !important;
  height: 47px;
}

.minimize-Notice {
  height: 70px !important;
}

@media screen and (max-width: 600px) {
  .minimize-Notice {
    height: 136px !important;
  }
}

@media (max-width: 1365px) {
  .tt-noticeDisplay {
    height: auto;
  }
  .noticeFooter {
    width: unset;
    margin-left: unset !important;
  }
  .noticeSlider {
    .slick-prev,
    .slick-next {
      line-height: 0;
      position: absolute;
      top: 39%;
      display: block;
      width: 40px;
      height: 154px;
      padding: 0;
      transform: translate(0, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background: $whiteColor !important;
    }
  }
  .noticeSlider {
    .slick-prev,
    .slick-next {
      background: unset !important;
    }
  }
}

.ackHeader {
  width: 100%;
  display: flex;
  padding: 5px;
  color: $whiteColor;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);
  justify-content: space-between;
}

.ackHeader-fixed {
  position: fixed;
  bottom: 72px;
  height: 460px;
  right: 40px;
  width: 300px;
  transition: 0.5;
}

.ackBody {
  height: 380px;
  overflow: auto;
}

.ackHeader-minimize {
  height: 35px;
  overflow: hidden;
  transition: 0.5;
}

//For Profile Picture notice
.noticeTextContent {
  img {
    width: auto;
    max-height: 300px;
  }
}

.cp-moderationImageHolder {
  height: 220px;
  border: 1px solid #ccc;
  margin-top: 15px;
  margin-bottom: 6px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.postModerationHolder {
  border: 1px solid #ccc;
  box-shadow: 1px 2px 3px $editButtonColor;
  padding-bottom: 10px;
  padding-top: 5px;
}

.managePaddingMargin {
  padding-left: 0 !important;
  margin-top: 3px;
}

.tt-modalMobileNoticeFooter {
  text-align: right;
  margin-top: 0.25rem;
}

//Responsive
.tt-showMobileGroup {
  display: none;
}

.acceptChangeButton {
  margin-right: 1rem;
}

.mobileNoticeButton {
  display: none !important;
}

@media screen and (min-width: 990px) and (max-width: 1024px) {
  .noticeFooter {
    width: 111%;
    margin-left: -45px !important;
  }
}

@media screen and (max-width: 990px) {
  .noticeFooter {
    width: 111%;
    margin-left: -34px !important;
  }
}

@media screen and (max-width: 1024px) {
  #noticeModalBody {
    height: 73vh;
  }
  .tt-noticeDisplay {
    .tt-noticeSlider {
      .tt-noticeButton {
        right: -12px;
      }
    }
  }
  .tt-acknowledgeListBox {
    display: none !important;
  }
  .tt-noticeModal {
    margin: 10px !important;
  }
  .tt-noticesSubData {
    margin-top: 4px;
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .tt-noticeFooterText {
    display: none;
  }
  .noticeFooter {
    margin-left: -25px !important;
  }
  .tt-noticesSubData {
    margin-bottom: 5px;
    margin-top: 0;
  }
  .managePaddingMargin {
    padding-left: 15px !important;
    margin-top: 2px;
  }
  .tt-mobileNoticeGroup {
    display: none;
  }
  .tt-showMobileGroup {
    display: block;
  }

  .noticeSlider .slick-prev,
  .noticeSlider .slick-next {
    width: 19px;
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 20px;
    color: rgba(66, 118, 234, 0.6);
  }
  #noticeModalBody {
    height: 65vh;
  }
  .tt-modalMobileNoticeFooter {
    text-align: center;
    margin-top: 0;
    margin-bottom: 5px;
  }
  .tt-commentMobileButton {
    text-align: right;
  }
  .hideNoticeButton {
    display: none !important;
  }
  .mobileNoticeButton {
    display: flex !important;
  }
  .viewButton {
    width: 20%;
  }
  .dateChoose {
    width: 80%;
    padding-right: 15px;
  }
}

@media screen and (max-width: 480px) {
  .noticeFooter {
    margin-left: -22px !important;
  }
  .viewButton {
    width: 25%;
  }
  .dateChoose {
    width: 75%;
  }
}

@media screen and (max-width: 414px) {
  .noticeFooter {
    margin-left: -19px !important;
  }

  .tt-groupTargetList {
    div {
      width: 140px;
    }
  }

  .tt-mobileButton {
    font-size: 12px !important;
  }
  .acceptChangeButton {
    margin-right: 0;
  }
}

@media screen and (max-width: 400px) {
  .noticeFooter {
    margin-left: -17px !important;
    margin-bottom: 0;
  }
  .tt-noticeDisplay {
    .tt-noticeSlider {
      height: auto;
      .tt-noticeDate {
        margin-bottom: 0;
      }
    }
  }
  .tt-mobileButton {
    font-size: 10px !important;
  }
  .viewButton {
    width: 30%;
  }
  .dateChoose {
    width: 70%;
  }
}
@media screen and (max-width: 393px) {
  .tt-noticeModalDate {
    font-size: 11px;
  }
}

@media screen and (max-width: 375px) {
  .noticeFooter {
    margin-left: -16px !important;
  }
  .tt-noticeDisplay {
    .tt-noticeSlider {
      //   height: 204px;
      .tt-noticeHeading {
        font-size: 18px;
      }
      .tt-noticeContent {
        font-size: 13px;
        height: 61px;
        P {
          font-size: 13px;
          margin-bottom: 0;
          line-height: 20px;
        }
      }
    }
  }
  #noticeModalBody {
    height: 60vh;
  }
}
@media screen and (max-width: 360px) {
  .tt-noticeModalDate {
    font-size: 10px;
  }
  .tt-mobileButton {
    font-size: 9px !important;
  }
  .tt-noticeDisplay {
    .tt-noticeSlider {
      .tt-noticeDate {
        font-size: 13px;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .noticeFooter {
    margin-left: -14px !important;
  }
  .tt-noticeModalDate {
    font-size: 9px;
  }
  .tt-bubble-background {
    font-size: 11px !important;
  }
  .tt-noticeDisplay {
    .tt-noticeSlider {
      .tt-noticeDate {
        font-size: 10px;
      }
    }
  }
  .viewButton {
    width: 35%;
    margin-top: 5px;
  }
  .dateChoose {
    width: 65%;
  }
}
