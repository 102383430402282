@import "./variables";

.group-header-checkbox {
  margin-top: 5px;
  height: 15px;
  width: 15px;
}

.group-header-text {
  position: relative;
  top: -3px;
  left: 5px;
}

.form-label {
  position: absolute;
  margin-bottom: -7px !important;
}

.bday-picker {
  z-index: 9999999 !important;
  top: 2px !important;
  //   width: 61px !important;
  opacity: 0 !important;
  margin-left: -32px !important;
}

._2xcMq {
  width: 300px;
  height: auto;
  // box-shadow: 5px 3px 12px 0px rgb(16 36 94 / 20%);
  position: absolute;
  background-color: #fff;
  z-index: 1000 !important;
  font-size: 12px !important;
  line-height: 1.15 !important;
  color: #000;
  margin-left: -150px;
  margin-top: 10px;
  text-shadow: none;
  // padding: 20px;
}
._249_g {
  padding: 10px 4px !important;
  flex-basis: 42px;
  text-align: center;
}

._1oYOS {
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  right: 0px;
  z-index: 1;
  opacity: 0;
  width: 50px;
}

.datePicker-ad {
  font-size: 16px;
  line-height: 1.5;
  position: relative !important;
  right: 52px;
  top: 3px;
  z-index: 1;
  opacity: 0 !important;
  width: 70px;
}

.bday-fa {
  /* float: right !important; */
  /* display: flex; */
  position: absolute;
  font-size: 1.2rem;
  margin-right: 35px !important;
  margin-top: -20px !important;
  cursor: pointer;
}

.indicate-error {
  border: 1px solid $dangerBtnColor !important;
}

.error-text {
  font-size: 0.8rem;
  color: $dangerBtnColor;
  top: 7px;
  position: relative;
}

.customfield-error-text {
  font-size: 0.7rem;
  color: $dangerBtnColor;
  top: 6px;
  margin-right: 5px;
  margin-left: -11px;
  position: relative;
}

.tt-department-table {
  height: 34.6vh;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.group-name {
  font-weight: bold;
}
.admission-notice {
  font-style: italic;
  font-size: 0.8rem;
}
.notice-text {
  margin-left: 50px;
  padding: 5px;
}
