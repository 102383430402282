@import "./variables";

.tt-post-box {
  border: 7px solid $whiteColor;
  box-shadow: 2px 3px 3px $shadowColor;
  margin-top: 20px;
  background-color: $whiteColor;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;

  // .tt-post-section {
  //     text-align: center;
  //     font-weight: bold;
  //     height: 90.5%;
  //     border-radius: 5px;
  //     margin: 0;
  //     border: 1px solid $primaryColor;
  // }
}

.tt-post-search-box {
  border: 7px solid $whiteColor;
  box-shadow: 2px 3px 3px $shadowColor;
  margin-top: 20px;
  background-color: $whiteColor;
  height: 30.5%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  // .tt-post-section {
  //     text-align: center;
  //     font-weight: bold;
  //     height: 90.5%;
  //     border-radius: 5px;
  //     margin: 0;
  //     border: 1px solid $primaryColor;
  // }
}

.tt-post-section {
  border: 7px solid $whiteColor;
  box-shadow: 2px 3px 3px $shadowColor;
  margin-top: 20px;
  background-color: $whiteColor;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}

.tt-post-area {
  // padding: 5px;
  // margin-bottom: 7px;
  background-color: $primaryColor;
  border-radius: 5px;
}

.tt-post-content {
  // border: 1px solid $whiteColor !important;
  padding: 10px;
  width: 100% !important;

  &::placeholder {
    color: $primaryColor;
  }
}

//Tabs for post
.rwt__tab {
  text-align: left;
  h6 {
    text-align: center;
  }
}
.tt-postTabs {
  .rwt__tablist:not([aria-orientation="vertical"]) {
    text-align: right;
    border-bottom: none !important;
  }

  .rwt__tab {
    height: 35px !important;
    width: 171px !important;
    padding: 0 !important;
    font-weight: 500;
    margin-right: 0;
    box-shadow: 0px 0px 2px $primaryHoverColor !important;
    text-align: center;

    &:first-child {
      border-top-left-radius: 10px !important;
    }

    &:last-child {
      border-top-right-radius: 10px !important;
      border-right: none !important;
    }
  }

  .rwt__tabpanel {
    padding: 5px;
    background-color: $primaryColor;
    height: 81.8vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.ck.ck-editor__main > .ck-editor__editable {
  height: 140px;
}

.tt-article {
  .ck.ck-editor__main > .ck-editor__editable {
    height: 79px;
  }

  .tt-postNoticeBoxes {
    height: 110px;

    .tt-postEmbeded {
      height: 74px;
    }
  }
}

.ck-content {
  p {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
}

.ck-editor {
  margin-bottom: 5px !important;
}

.tt-postNoticeBoxes {
  margin-top: 10px;
  border: 1px solid $primaryHoverColor;
  padding: 5px;
  height: 150px;
  background-color: $whiteColor;

  .tt-postFiles {
    display: flex;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    .tt-fileDelete {
      position: relative;
      top: -95px;
      left: 37px;
      cursor: pointer;
    }

    .tt-postFileHolder {
      margin: 5px;
      border: 1px solid $primaryHoverColor;
      margin: 5px;
      padding: 10px 30px;
      height: 98px;
      flex: 0 0 100px;
      text-align: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px;
      width: 100px;

      .tt-postFileIcon {
        font-size: 50px;
        padding: 3px;
        cursor: pointer;
      }

      img {
        width: 98px;
        height: 96px;
        object-fit: cover;
      }

      div {
        width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        top: 7px;
      }
    }
  }

  .tt-postEmbeded {
    height: 120px;
    overflow-x: hidden;
    overflow-y: auto;

    p {
      margin-bottom: 0;
    }
  }
}

.tt-postNoticeHeading {
  font-weight: bold;
}

.tt-videoContainer {
  border: 1px solid $primaryHoverColor;
  border-radius: 25px;
  background-color: $primaryColor;
  margin: 0 20px 20px 10px;
  height: 315px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;

  iframe {
    height: 255px;
    background-color: #fff;
    width: 90%;
    border: 1px solid $primaryHoverColor;
  }
}

.tt-buttonChips {
  display: inline-flex;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 5px;
  color: $whiteColor;

  &::-webkit-scrollbar {
    height: 8px;
  }

  div {
    padding: 3px 20px;
    border-radius: 25px;
    margin: 6px;
  }

  .red {
    background-color: $calendarHolidayColor;
  }

  .blue {
    background-color: $primaryBtnColor;
  }

  .green {
    background-color: $secondaryBtnColor;
  }
}

.tt-videoClose {
  position: absolute;
  top: 10px;
  right: 13px;
  font-size: 20px;
  cursor: pointer;
}

.tt-inner-content {
  .rwt__tablist:not([aria-orientation="vertical"]) {
    text-align: left;
  }

  .rwt__tab {
    margin: 1px;
    text-align: center;
  }

  .rwt__tabpanel {
    padding: 0;
    background-color: $whiteColor;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid $calendarBorderColor;
  }
}

.post-checkbox {
  position: relative;
  top: 3px;
}

.search-minimize {
  height: 55px !important;
}

.tt-mediaFileHolder {
  text-align: center;
  img {
    height: 240px;
    width: 75%;
    object-fit: cover;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .tt-mediaFileIcon {
    font-size: 50px;
    padding: 3px;
    cursor: pointer;
  }
  div {
    font-size: 20px;
  }
}

.tt-add-question-form {
  display: flex;
}

// .displayImageBoxCenter {
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

//Media Image Holder
.tt-mediaImageHolder {
  display: flex;
  /* flex-direction: column; */
  height: 255px;
  width: 90%;
  max-width: 90%;
  //   background: $whiteColor;
  overflow: auto;
  // justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  border: 1px dashed $primaryHoverColor;
  padding: 5px;
  .fullWidthImage {
    flex: 1;
  }
  div {
    padding: 5px;
    position: relative;
    // flex: 1;
    width: 50%;
    img {
      width: 100%;
      height: 230px;
      object-fit: cover;
      box-shadow: 2px 3px 3px $shadowColor;
    }
    .tt-fileDelete {
      position: absolute;
      right: 13px;
      top: 13px;
      cursor: pointer;
    }
    .imageMediaHeight {
      height: 170px;
    }
  }
}

// .tt-mediaPostSection {
//   .jodit_container:not(.jodit_inline) {
//     min-height: 200px !important;
//   }
//   .jodit_container:not(.jodit_inline) .jodit_wysiwyg {
//     min-height: 140px !important;
//   }
//   .jodit_workplace {
//     height: 140px !important;
//   }
// }

.mediaQuestions {
  height: 135px;
}

.jodit_container:not(.jodit_inline) .jodit_wysiwyg {
  cursor: text;
}
// .jodit_wysiwyg {
//   height: 20px !important;
//   overflow-y: scroll !important;
// }
.css-4ljt47-MenuList {
  max-height: 200px !important;
}

.jodit_workplace sup {
  font-size: xx-small !important;
  vertical-align: super !important;
  float: none !important;
  color: inherit !important;
  right: 0em !important;
  top: unset !important;
}

.css-at12u2-loadingIndicator {
  color: $primaryBtnColor !important;
  font-size: 6px !important;
}

.questionTitle {
  text-align: right;
}

.mobileMediaImageAdd {
  padding-left: 75px !important;
}

//Responsive
.tt-mobilePostKeywords {
  display: none;
}

.tt-userInfoMobile {
  display: none !important;
}

.mobilePPimage {
  width: 90px !important;
}

//Post Update Delete
.tt-postUpdateDeleteIcon {
  div {
    .btn-secondary {
      padding: 0;
      padding-left: 5px;
      padding-right: 5px;
      background-color: unset !important;
      color: $breadCrumbColor;
      border: none;
      font-size: 13px;
      &:active,
      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }
    .tt-post-dropdown {
      box-shadow: 2px 3px 3px $shadowColor;
      .tt-post-dropbtn {
        &:hover {
          background-color: $primaryHoverColor;
          color: $whiteColor;
        }
        &:focus {
          outline: none;
        }
        &:active {
          background-color: $primaryColor;
        }
      }
    }
    .managePositionDropdown {
      left: -130px !important;
      line-height: 1.5;
    }
  }
}

@media screen and (max-width: 768px) {
  .tt-postUpdateDeleteIcon {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .tt-postTabs .rwt__tab {
    width: fit-content !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    font-size: 13px;
  }
  .academiRepoMarker {
    display: none;
  }
  .img-postDisplay {
    width: 50px !important;
    height: 50px !important;
  }
  .tt-desktopPostKeywords {
    display: none;
  }
  .tt-mobilePostKeywords {
    display: block;
  }
  .acaRepoMobileTaskbar {
    display: none !important;
  }

  .tt-mobileCreatePost {
    height: 98%;
    .modal-content {
      .modal-body {
        .tt-postTabs {
          height: 84vh;
          overflow: auto;
          .rwt__tabpanel {
            height: 76vh;
          }
        }
      }
    }
  }
  .tt-videoContainer {
    height: 250px;
    iframe {
      height: 200px;
    }
  }
  .tt-mediaImageHolder {
    height: 200px;
    div .imageMediaHeight {
      height: 115px;
    }
    img {
      height: 175px !important;
    }
  }
  .mobileMediaImageAdd {
    padding-left: 41px;
  }
  .tt-post-box,
  .tt-post-section {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .mobilemarginView {
    display: none !important;
  }
  #tt-postBox {
    display: none !important;
  }
  .tt-userInfoMobile {
    display: block !important;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .tt-postTabs .rwt__tab {
    font-size: 14px;
  }
  .mobileMediaImageAdd {
    text-align: right;
    margin-top: 15px;
  }
  .mobileMediaImageButton {
    margin-top: 15px;
  }
  .tt-videoContainer {
    margin: 0 0 20px 0;
  }
}

@media screen and (max-width: 480px) {
  .mobilePPimage {
    width: 100px !important;
  }
  .tt-buttonChips {
    justify-content: center;
  }
  .tt-article {
    .tt-postNoticeBoxes {
      margin-bottom: 10px !important;
    }
  }
  .tt-postNoticeHeading {
    text-align: center;
  }
  .manageTOp {
    margin-top: 10px !important;
  }
  .questionTitle,
  .acaCheckbox {
    text-align: center;
    margin-top: 5px;
  }
}

@media screen and (max-width: 400px) {
  .mobilePPimage {
    width: 80px !important;
  }
}

@media screen and (max-width: 393px) {
  .tt-postTabs .rwt__tab {
    font-size: 12px;
  }
}

@media screen and (max-width: 360px) {
  .tt-buttonChips div {
    font-size: 11px;
  }
}

@media screen and (max-width: 320px) {
  .tt-postTabs .rwt__tab {
    font-size: 11px;
  }
  .tt-buttonChips div {
    padding: 3px 15px;
    font-size: 10px;
  }
  .mobilePPimage {
    width: 75px !important;
  }
}

//Record History
.tt-historyBorder {
  border: 1px solid #ccc;
  height: 79vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
}
