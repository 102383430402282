@import "./variables";
.tt-cover-image {
  border: 7px solid $whiteColor;
  box-shadow: 2px 3px 3px $shadowColor;
  height: 375px;
  position: relative;
  top: -59px;
  transition: 0.8s;
  border-radius: 10px;
  p {
    text-align: center;
    font-weight: bold;
  }
}

.tt-slider-content {
  font-size: 25px;
  text-align: center;
  transition: height 0.8s;
  height: 368px;
  background-color: $whiteColor;
  z-index: 50000;
}

.img {
  transition: height 0.8s;
}

.tt-slider-content-minimize {
  height: 146px !important;
  img {
    height: 145px !important;
    transition: height 0.8s;
  }
}
.noticeSlider {
  .slick-prev,
  .slick-next {
    line-height: 0;
    position: absolute;
    top: 39%;
    display: block;
    width: 40px;
    height: 154px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: $whiteColor !important;
  }
}

.slick-next,
.slick-prev {
  width: 50px;
  z-index: 3;
  &:before {
    font-size: 30px;
    color: $displayBackgroundColor;
  }
  &:hover:before {
    color: $displayBackgroundHoverColor;
  }
}

.slick-next {
  right: 0;
}

.slick-prev {
  left: 0;
}

.slick-dots {
  bottom: 15px;
}

.tt-editButton {
  display: none;
  opacity: 0;
  transition: 0.5s;
  line-height: normal;
  font-size: 16px;
  position: absolute;
  top: 28.5%;
  right: 5em;
  cursor: pointer;
  border-radius: 3px;
  color: $whiteColor;
  padding: 5px 30px;
  text-shadow: 1px 1px 2px $borderColor;
  background: $displayBackgroundColor;
  &:hover {
    background: $displayBackgroundHoverColor;
  }
}

.tt-systemDefault {
  display: none;
  opacity: 0;
  transition: 0.5s;
  line-height: normal;
  font-size: 16px;
  position: absolute;
  top: 10%;
  right: 5em;
  cursor: pointer;
  border-radius: 3px;
  color: $whiteColor;
  padding: 5px 30px;
  text-shadow: 1px 1px 2px $borderColor;
  background: $displayBackgroundColor;
  &:hover {
    background: $displayBackgroundHoverColor;
  }
}

.tt-personalChange {
  display: none;
  opacity: 0;
  transition: 0.5s;
  line-height: normal;
  font-size: 16px;
  position: absolute;
  top: 19%;
  right: 5em;
  cursor: pointer;
  border-radius: 3px;
  color: $whiteColor;
  padding: 5px 30px;
  text-shadow: 1px 1px 2px $borderColor;
  background: $displayBackgroundColor;
  &:hover {
    background: $displayBackgroundHoverColor;
  }
}

.tt-minimize {
  position: relative;
  width: 55px;
  left: 95%;
  top: -25px;
  color: $borderColor;
  background-color: $primaryColor;
  z-index: 9;
  line-height: 3;
  text-align: center;
  width: 25px;
  color: $whiteColor;
  cursor: pointer;
  box-shadow: 1px 1px 5px $borderColor;
  &:hover {
    background-color: $primaryHoverColor;
  }
}

.tt-cover-minimize {
  height: 160px;
}

.slick-slide {
  img {
    width: 100%;
    height: 361px;
    object-fit: cover;
    border-radius: 5px;
    transition: height 0.8s;
    z-index: 1000;
  }
}

.tt-coverPicPreview {
  width: 100%;
  height: 41vh;
  text-align: center;
  cursor: pointer;
  color: $primaryColor;
  border: 1px solid $primaryColor;
  img {
    object-fit: cover;
    width: 100%;
    height: 40.71vh;
  }
  &::hover {
    box-shadow: 1px 2px 5px $borderColor;
  }
}

.coverModalPrev {
  margin: 5px auto !important;
}

.tt-fullImageCaption {
  background-color: $editButtonColor;
  color: $whiteColor;
  font-size: 23px;
  position: absolute;
  bottom: 7px;
  width: 96.1%;
  padding: 5px;
}

.tt-highlight-button {
  background: $primaryBtnHoverColor;
}

.selectedThumbnail {
  box-shadow: 2px 3px 3px $breadCrumbColor;
}

.fullWindow-Spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  color: $whiteColor;
  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
}

.modal-dialog {
  margin: 1.5rem auto;
}

@media screen and (max-width: 600px) {
  .tt-fullImageCaption {
    width: 95%;
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .tt-fullImageCaption {
    width: 93.5%;
  }
}

@media screen and (max-width: 414px) {
  .tt-fullImageCaption {
    width: 92.5%;
    font-size: 12px;
  }
}

@media screen and (max-width: 393px) {
  .tt-fullImageCaption {
    width: 92%;
  }
}

@media screen and (max-width: 375px) {
  .tt-fullImageCaption {
    font-size: 11px;
  }
}
@media screen and (max-width: 360px) {
  .tt-fullImageCaption {
    width: 91.4%;
  }
}

@media screen and (max-width: 320px) {
  .tt-fullImageCaption {
    width: 90.3%;
    font-size: 10px;
  }
}
