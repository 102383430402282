@import "./variables";

body {
  // background-color: $contentBackgroundColor !important;
  background: url("../images/silverBg3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  font-size: 13px;
}

a:hover {
  text-decoration: none !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: $loginBackgroundColor;
}

::-webkit-scrollbar-thumb {
  // background: $primaryColor;
  background: $gradientBg;
  border-radius: 10px;
  border: 1px solid $whiteColor;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: $primaryHoverColor;
}

.tt-group-header {
  padding: 10px !important;
  margin-bottom: 7px;
  background-color: $primaryColor !important;
  font-weight: bold;
  border-radius: 5px;
  letter-spacing: 0.5px;
  cursor: default;
  color: $whiteColor;
  text-shadow: 1px 1px 2px $borderColor;
  user-select: none;
}

.attendance-top-header {
  padding: 10px !important;
  margin-bottom: 7px;
  background-color: $primaryColor !important;
  border-radius: 5px;
  cursor: default;
  user-select: none;
  label {
    font-weight: bold;
    letter-spacing: 0.5px;
    color: $whiteColor;
    text-shadow: 1px 1px 2px $borderColor;
  }
}

.tt-group-header-dark {
  padding: 10px;
  margin-bottom: 7px;
  background-color: $primaryColorDark;
  font-weight: bold;
  border-radius: 5px;
  letter-spacing: 0.5px;
  cursor: default;
  color: $whiteColor;
  text-shadow: 1px 1px 2px $borderColor;
  //   color: $whiteColor;
}

.form-control {
  box-shadow: inset 7px 7px 10px -5px $shadowColor;
}

.form-control-warn {
  border: 1px solid red;
}

.css-2613qy-menu {
  height: auto;
  z-index: 100;
  color: red;
}

/*------------------------Buttons---------------------*/

@mixin btnColors($color, $bgColor, $bgImage) {
  color: $color !important;
  background-color: $bgColor !important;
  background-image: linear-gradient($bgColor, $bgImage) !important;
}

.tt-button {
  display: inline-block;
  position: relative;
  margin-left: auto;
  padding: 4px 20px;
  font-size: 13px;
  outline: none !important;
  border: 1px solid $whiteColor;
  border-radius: 5px;
  //   margin-top: 10px;
  @include btnColors($whiteColor, $cancelBtnColor, $cancelGradientColor);

  &:disabled {
    @include btnColors($whiteColor, $cancelBtnColor, $cancelGradientColor);
    &:hover {
      @include btnColors($whiteColor, $cancelBtnColor, $cancelGradientColor);
    }
  }

  &:hover,
  &:focus {
    background: $CancelBtnHoverColor;
  }

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: 50%;
    background: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    );
  }
}

.tt-button-primary {
  @include btnColors($whiteColor, $primaryBtnColor, $primaryGradientColor);

  &:hover,
  &:focus {
    background: $primaryBtnHoverColor;
  }
}

.tt-button-danger {
  @include btnColors($whiteColor, $dangerBtnColor, $dangerGradientColor);

  &:hover,
  &:focus {
    background: $dangerBtnHoverColor;
  }
}

.tt-button-fullWidth {
  width: 100%;
}

.tt-content-section {
  position: relative;
  margin-top: -45px;
}

textarea {
  resize: none;
}

.no-border-td {
  .associateLevel {
    border: none !important;
  }
}

.range-minus {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 18px;
}

/*-------------------------------Modal-----------------------*/

.tt-modal {
  .modal-content {
    // height: -webkit-fill-available;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.modal-content {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  border-radius: 10px !important;
  border: 7px solid $whiteColor !important;
}

.modal-body {
  padding: 0 0.5rem !important;
  scroll-behavior: smooth;
}

.modal-header {
  position: sticky;
  top: 0;
  z-index: 9999;
}

/*-------------------------------For Suggestion Tabs-----------------------*/

.tt-horizontal {
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
}

/*-------------------------------For Inline Display-----------------------*/

.tt-inline {
  display: inherit;
}

.tt-widgetContent-tab-holder {
  border: 7px solid $whiteColor;
  box-shadow: 1px 1px 5px $shadowColor;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 4px;
}

h6 {
  margin-bottom: 0 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// cursors
.tt-cursor-pointer {
  cursor: pointer;
}

//Form
.form-control:focus {
  box-shadow: none !important;
  border-color: $primaryColor;
}

.tt-adjustPadding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  height: 15px;
  width: 20px;
}

.card-body {
  padding: 5px !important;
}

// Chips
.MuiChip-root {
  border-radius: 5px !important;
  margin: 2px !important;
  height: auto !important;
  padding: 3px 0 !important;
}

.MuiChip-colorPrimary {
  background-color: lighten($primaryHoverColor, 30%) !important;
  color: $editButtonColor !important;
  font-weight: 600;
}

.MuiChip-label {
  white-space: pre-wrap !important;
}

.form-group {
  margin-bottom: 6px !important;
}

table {
  td {
    padding: 5px !important;
    // vertical-align: middle !important;

    p {
      display: inline;
    }
  }
  th {
    vertical-align: middle !important;
  }
}

.rwt__tabpanel {
  cursor: pointer;
}

//paddings
.tt-paddingLeft {
  padding-left: 5px;
}

.tt-paddingRight {
  padding-right: 5px;
}

.tt_align_items_v_middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* flex-wrap: wrap; */
  //   align-items: center;
}

.tt_align_items_v_top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /*Aligns vertically center */
  //   justify-content: center; /*Aligns horizontally center */
}

.tt_align_items_v_bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /*Aligns vertically center */
  //   justify-content: center; /*Aligns horizontally center */
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: $tableAlternateBgColor;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $whiteColor;
}

.table-collapse-tt {
  tbody {
    // span:nth-child(even) {
    //   background-color: rgba(119, 152, 237, 0.3) !important;
    // }
    // span:nth-child(odd) {
    //   background-color: $whiteColor !important;
    // }
    .tt-colCollapse {
      background-color: $whiteColor !important;
    }
    .tt-colRow {
      &:nth-of-type(odd) {
        background-color: $whiteColor !important;
      }
    }
    .tt-colRow {
      &:nth-of-type(even) {
        background-color: $tableAlternateBgColor !important;
      }
    }
  }
}

.tt-trDiv {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.css-1w9j89e-menu {
  z-index: 1000 !important;
}

// .widgetHolder {
//   .rwt__tablist:not([aria-orientation="vertical"]) {
//     margin-bottom: 10px !important;
//   }
// }

.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 1px solid $calendarBorderColor !important;
}

// select {
//   background-color: $primaryColor;
// }

//CalendarButtons
.fc-button {
  display: inline-block;
  position: relative;
  margin-left: auto;
  padding: 4px 20px;
  font-size: 13px;
  outline: none;
  border: 1px solid $whiteColor;
  border-radius: 5px;
  outline: none;
  //   margin-top: 10px;
  @include btnColors($whiteColor, $cancelBtnColor, $cancelGradientColor);

  &:disabled {
    @include btnColors($whiteColor, $cancelBtnColor, $cancelGradientColor);
    &:hover {
      @include btnColors($whiteColor, $cancelBtnColor, $cancelGradientColor);
    }
  }

  &:hover,
  &:focus {
    background: $CancelBtnHoverColor;
  }

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: 50%;
    background: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    );
  }
}

.fc-button-primary {
  @include btnColors($whiteColor, $primaryBtnColor, $primaryGradientColor);

  &:hover,
  &:focus,
  &:visited {
    background: $primaryBtnHoverColor;
  }
}

//SWAL MODAL DESIGn

.swal-title {
  color: $whiteColor;
  font-weight: bold;
  position: relative;
  top: 0;
  display: block;
  padding: 10px;
  padding-bottom: 10px !important;
  font-size: 20px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.5px;
  border-radius: 5px;
  background-color: $primaryColor;
  margin-top: 0 !important;
  cursor: move;
  text-shadow: 1px 1px 2px $borderColor;
}

.swal-text {
  text-align: left;
  display: block;
  color: $borderColor;
  margin: 25px 0;
}

.swal-icon {
  top: 45px;
  box-sizing: content-box;
  margin: 20px auto;
}

.swal-overlay--show-modal .swal-modal {
  border: 7px solid $whiteColor;
  border-radius: 10px;
  //   position: absolute;
}

.swal-footer {
  background-color: $primaryColor;
  padding: 5px;
  border-radius: 5px;
}

.swal-button-container {
  margin: 3px;
}

.swal-button {
  display: inline-block;
  position: relative;
  margin-left: auto;
  padding: 4px 20px;
  font-size: 13px;
  outline: none;
  border: 1px solid $whiteColor;
  border-radius: 5px;
  //   margin-top: 10px;
  @include btnColors($whiteColor, $primaryBtnColor, $primaryGradientColor);

  &:not([disabled]):hover,
  &:focus {
    background-color: $primaryBtnHoverColor !important;
    box-shadow: none !important;
  }

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: 50%;
    background: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    );
  }
}

//swal-modal-design 2

.swal2-popup {
  padding: 0 !important;
  border: 7px solid $whiteColor !important;
  border-radius: 10px !important;
}
.swal2-header {
  background-color: $primaryColor;
  align-items: flex-start !important;
  border-radius: 5px;
  text-align: left !important;
  .swal2-title {
    color: $whiteColor;
    font-weight: bold;
    position: relative;
    top: 0;
    display: block;
    padding: 10px;
    padding-bottom: 10px !important;
    font-size: 20px;
    line-height: 20px;
    text-align: left;
    letter-spacing: 0.5px;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    cursor: move;
    text-shadow: 1px 1px 2px $borderColor;
  }
}

.swal2-content {
  text-align: left !important;
  display: block;
  color: $borderColor !important;
  margin: 5px 0 !important;
  /* font-size: 23px; */
  padding-left: 10px !important;
  .swal2-html-container {
    p {
      margin-bottom: 0 !important;
      font-size: 15px;
    }
    ol {
      padding-left: 15px;
      margin-bottom: 0 !important;
    }
  }
}

// .swal2-actions {
//   background-color: $primaryColor;
//   padding: 5px;
//   border-radius: 5px;
//   margin-top: 0 !important;
//   .swal2-confirm {
//     display: inline-block;
//     position: relative;
//     margin-left: auto;
//     padding: 4px 20px;
//     font-size: 13px;
//     outline: none;
//     border: 1px solid $whiteColor;
//     border-radius: 5px;
//     //   margin-top: 10px;
//     @include btnColors($whiteColor, $primaryBtnColor, $primaryGradientColor);

//     &:not([disabled]):hover,
//     &:focus {
//       background-color: $primaryBtnHoverColor !important;
//       box-shadow: none !important;
//     }

//     &:after {
//       content: "";
//       position: absolute;
//       top: 2px;
//       left: 2px;
//       width: calc(100% - 4px);
//       height: 50%;
//       background: linear-gradient(
//         rgba(255, 255, 255, 0.2),
//         rgba(255, 255, 255, 0.2)
//       );
//     }
//   }
// }
.swal2-actions {
  background-color: $primaryColor;
  padding: 5px;
  border-radius: 5px;
  margin-top: 0 !important;



  .swal2-confirm {
    border-left-color: white !important;
    border-right-color: white !important;
    display: inline-block !important;
    position: relative !important;
    margin-left: auto !important;
    padding: 4px 20px !important;
    font-size: 13px !important;
    outline: none !important;
    border: 1px solid $whiteColor !important;
    border-radius: 5px !important;
    //   margin-top: 10px;
    @include btnColors($whiteColor, $primaryBtnColor, $primaryGradientColor);
  
    &:not([disabled]):hover,
    &:focus {
      background-color: $primaryBtnHoverColor !important;
      box-shadow: none !important;
    }
  
    &:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: calc(100% - 4px);
      height: 50%;
      background: linear-gradient(
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      );
    }
  }

  .swal2-cancel{
    display: inline-block;
    position: relative;
    // margin-left: auto;
    padding: 4px 20px;
    font-size: 13px;
    outline: none;
    border: 1px solid $whiteColor;
    border-radius: 5px;
    //   margin-top: 10px;
    @include btnColors($whiteColor, $primaryBtnColor, $primaryGradientColor);
  
    &:not([disabled]):hover,
    &:focus {
      background-color: $primaryBtnHoverColor !important;
      box-shadow: none !important;
    }
  
    &:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: calc(100% - 4px);
      height: 50%;
      background: linear-gradient(
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      );
    }
  }
}

//Printing
.tt-printPreview {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background: $whiteColor;
  box-shadow: 2px 3px 3px $editButtonColor;
}

img {
  user-select: none;
}

label {
  user-select: none;
}
button {
  user-select: none;
}
.swal-modal {
  user-select: none;
}

.__react_component_tooltip.type-dark {
  color: $whiteColor;
  background-color: $tooltipColor !important;
}

.__react_component_tooltip.type-dark.place-top:after {
  border-top-color: $tooltipColor !important;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: $calendarBorderColor;
}

.fc-sat {
  color: $calendarHolidayColor;
  font-weight: bold;
  // background-color: $calendarBorderColor;
}

.listedTeachers {
  span:last-child {
    span:last-child {
      display: none;
    }
  }
}

.tt-infoTable {
  position: relative;
  box-shadow: 2px 3px 3px $shadowColor;
}

.schoolInformation {
  height: 61.8vh;
  overflow-y: auto;
  overflow-x: hidden;
}

//Picture holders
.tt-ppHolder {
  width: 55px;
  height: 55px;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  box-shadow: 2px 3px 3px $shadowColor;
  img {
    width: 55px;
    height: 55px;
    object-fit: cover;
  }
}

.tt-ppName {
  width: 140px;
  display: inline-block;
  overflow-wrap: break-word;
}
.tt-pp {
  display: flex;
  flex-direction: row;
  align-items: center;
}

iframe {
  background-color: $borderColor;
}
a {
  color: $borderColor;
  &:hover {
    text-decoration: none;
  }
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

.alignContent {
  text-align: right;
  padding-top: 6px;
}

table thead tr th {
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  background-color: $primaryColor;
  z-index: 90;
}

select.form-control[multiple] {
  height: 120px;
}
.css-26l3qy-menu {
  z-index: 1000 !important;
}

.tt-peopleList {
  .modal-body {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}

select {
  box-shadow: inset 7px 10px 10px -5px $shadowColor;
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
  color: $whiteColor;
  background-color: $primaryBtnHoverColor;
  border-color: $primaryBtnHoverColor;
}

.tt-listTable {
  thead tr th {
    position: sticky;
    position: -webkit-sticky;
    top: 34px;
    background-color: $primaryColor;
    z-index: 100;
  }
}

.bootstrap-select {
  .dropdown-toggle {
    border: 1px solid #ccc;
  }
}
// :not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]) {
//   width: 120px;
// }
.bootstrap-select .dropdown-menu li a span.text {
  border: none !important;
}

.tt-newListBox {
  border: 1px solid #ccc;
  padding: 5px 15px;
  height: 69.6vh;

  overflow: auto;
  table thead tr th {
    top: -8px !important;
  }
  .tt-stickyHeader {
    top: -5px !important;
  }
}

.tt-admissionListBox {
  border: 1px solid #ccc;
  padding: 5px;
  height: 69.6vh;
  overflow: auto;
  table thead tr th {
    top: 25px !important;
  }
}
.tt-smallListBox {
  border: 1px solid #ccc;
  padding: 5px;
  height: 36.4vh;
  overflow: auto;
  table thead tr th {
    top: -8px !important;
  }
}

//Widget Spinner
.tt-widgetSpinner {
  position: fixed;
  background-color: $shadowColor;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  justify-content: center;
  align-items: center;
  left: 0;
  .spinner-border {
    width: 5rem;
    height: 5rem;
  }
}

.permissionBtnCSS {
  top: -4px;
}

#tt-permissionModal {
  .modal-content {
    overflow: unset !important;
  }
}

.postQuestions {
  max-height: 68vh;
  overflow-x: hidden;
  overflow-y: auto;
}

input::-webkit-calendar-picker-indicator {
  opacity: 1;
}

//For error Display

.tt-errorBackground {
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .tt-errorMessageHolder {
    background: $whiteColor;
    border: 7px solid $whiteColor;
    border-radius: 6px;
    width: 500px;
    box-shadow: 2px 3px 3px $shadowColor;
  }
}

.tt-assignSearchDropdown {
  background-color: $primaryColor;
  text-shadow: 1px 1px 2px $borderColor;
  color: $whiteColor;
  padding: 3px;
}

@media screen and (max-width: 700px) {
  .mobileWidgetArea {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .reverseContent {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 360px) {
  .tt-button {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .tt-button {
    font-size: 10px;
  }
}

input {
  overflow: hidden !important;
}

/**global snackbar override**/
.MuiSnackbarContent-root {
  background-color: #7798ed !important;
  color: rgb(0, 0, 0) !important;
}

//added 1/1 for valdiation indication
.indicate-error {
  border: 1px solid $dangerBtnColor !important;
}

.optional {
  color: $optional !important;
}
.assignmentDescription {
  border-style: outset;
  border-width: 2px;
  border-color: $primaryColor;
}

//for image file viewer
.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container > img {
  width: 70% !important;
  height: unset !important;
}

.pdf-canvas {
  canvas {
    display: block;
    margin: auto;
  }
}

.fullModal {
  min-width: 90vw !important;
  // height: 80vh !important;
  // max-width: none;

  .modal-body {
    //   max-width: 88vw !important;
    .file-preview-col,
    .file-list-col {
      max-height: 78vh !important;
      min-height: 70vh !important;
      overflow: scroll;
      overflow-x: hidden;

    }

    .download-col {
      background: $primaryColor;
      margin-bottom: -42px;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      font-weight: bold;
      // text-transform: uppercase;
      text-shadow: 1px 2px 2px $shadowColor;
      h6 {
        color: #fff;
        font-size: 20px;
        margin-bottom: 0;
        padding: 10px;
      }
    }
  }
  .activeIndex {
    color: #7798ed;
  }
}

//Information
.tt-profile-assignment {
  max-height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
}

.tt-inventoryItemHolder {
  border: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.myCss {
  option:disabled {
    color: #ccc;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  margin: 5px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 16px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: $primaryBtnColor;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $primaryBtnColor;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

@media print {
  .printWorkSheet {
    overflow: hidden;
    padding: 100px !important;
  }
  .printPage {
    @page {
      size: landscape;
      margin-bottom: 30px;
    }
    // height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .printPlanner {
    @page {
      size: landscape;
    }
    // overflow: hidden;
    height: 0;
    size: landscape;
    margin: 0 !important;
    padding: 100px !important;
    table {
      border: 2px solid black !important;

      thead {
        border: 2px solid black !important;

        tr {
          border: 2px solid black !important;

          th {
            border: 2px solid black !important;
          }
        }
      }

      tbody {
        tr {
          border: 2px solid black !important;

          td {
            border: 2px solid black !important;
          }
        }
      }
    }
  }
}

._3fmCl {
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
._2aNF9 {
  font-size: 16px;
  line-height: 1.5;
  padding: 2px;
  width: auto;
}

.c-pill {
  align-items: center;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
  font-weight: bold;
  font-size: 11px;
  display: inline-block;
  height: 100%;
  white-space: nowrap;
  width: auto;

  position: relative;
  border-radius: 100px;
  line-height: 1;
  overflow: hidden;
  padding: 0px 12px 0px 20px;
  text-overflow: ellipsis;
  line-height: 1.25rem;
  color: #595959;

  word-break: break-word;

  &:before {
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 6px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 10px;
  }
}

.c-pill--success {
  background: #b4eda0;
}
.c-pill--success:before {
  background: #6bc167;
}
.c-pill--warning {
  background: #ffebb6;
}
.c-pill--warning:before {
  background: #ffc400;
}
.c-pill--danger {
  background: #ffd5d1;
}
.c-pill--danger:before {
  background: #ff4436;
}

.bill-receipt-pdf {
}

.payment-print {
  @media print {
    @page {
      size: landscape;
    }
  }
}

// otp page

.otp-page {
  height: 420px;
  background-color: rgb(253, 253, 253);
  display: flex;
  align-items: center;
  padding: 100px;

  .otp-img img {
    width: 80px;
    height: 80px;
    margin-bottom: 1rem;
  }

  .otp-code {
    margin-bottom: 1rem;

    .otp-input {
      width: 30px;
      height: 30px;
      margin: 10px;
      text-align: center;
    }
  }
}

.search-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;

  input {
    width: 400px;
  }
}


.lms-input-number{
  input[type=number]{
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
}