@import "./variables";

.tt-nav {
  //border: 1px solid $borderColor;
  background-color: $primaryColor !important;
  box-shadow: none !important;
  padding: 20px 15px 50px 15px;
  // height: 135px;
  //   height: 50px;
  .tt-navbar-heading {
    text-align: center;
    color: $whiteColor;
    font-weight: bold;
    cursor: default;
    font-size: 26px;
    position: relative;
    top: 12px;
    text-shadow: 1px 1px 1px $borderColor;
  }

  .tt-nav-div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tt-nav-schoolname {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      img {
        max-width: 250px;
        min-width: 150px;
        height: 100px;
        object-fit: contain;
      }

      &.landscape {
        flex-direction: column;
        img {
          height: 80px;
          width: 250px;
          object-fit: contain;
        }
      }
    }
  }
}

.tt-menu-bar {
  float: right;
  position: relative;
  font-size: 20px;
  //   top: -36px;
  // top: -62px;
  div > button.btn-secondary {
    color: $whiteColor;
    font-size: 26px;
    // font-size: 20px;
    background-color: unset !important;
    border-color: unset !important;
    border: none;
    box-shadow: none;
    &:focus {
      box-shadow: none;
      outline: none;
    }
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
      outline: none;
    }
  }
}

.tt-dropdown-content {
  left: -85px !important;
  width: 215px !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-color: $primaryColor;
  transform: translate3d(-95px, 40px, 0px) !important;
  box-shadow: 2px 3px 3px $shadowColor;

  .tt-dropdown-logo {
    display: block;
    margin: auto;
    max-width: 80px;
    max-height: 80px;
    object-fit: contain;
    margin-top: 10px;
    margin-bottom: 8px;
  }
  .tt-dropdown-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $borderColor;
    font-weight: 500;
    span.badge {
      line-height: 1.5;
      font-size: 17px;
      color: $primaryHoverColor;
      padding-right: 0;
    }
    &:active {
      background-color: $primaryColor;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: $primaryHoverColor;
      color: $whiteColor;
    }
    &:hover > span.badge {
      color: $whiteColor;
    }
  }
}

.navHeight {
  // height: 50px;
}

.menuBarHeight {
  // top: -41px;
}
.menu-link {
  font-weight: 400 !important;

  color: $borderColor !important;
  text-decoration: none !important;
}

//Sub Menu
#tt-subMenu {
  display: none;
  margin-top: 6px;
  font-size: 13px;
  .tt-dropdnItem {
    top: unset;
  }
  .badge {
    padding-left: 2px;
    padding-right: 0.4em;
    font-size: 11px;
  }
}

#tt-subMenu-linkedAccount {
  display: none;
  margin-top: 6px;
  font-size: 13px;
}
.tt-dropdnItem {
  top: unset;
}
.badge {
  padding-left: 2px;
  padding-right: 0.4em;
  font-size: 11px;
}
.dropdown-item.active {
  background-color: $primaryColor;
  color: $whiteColor;
}
.dropdown-item.active > span.badge {
  color: $whiteColor;
}

.change-password {
  margin-left: 20px !important;
}

.aboutUsModal {
  max-width: 740px !important;
}

//Scroll Effect for About Us
.outer-wrapper {
  overflow: hidden;
  position: relative;
  width: 420px;
  height: 240px;
}

.wrapper {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  animation: creditEffect 25s linear infinite;
  p {
    a {
      color: $primaryHoverColor;
    }
  }
  &:hover {
    animation-play-state: paused;
  }
}
:root {
  --foo: -201%;
}

@keyframes creditEffect {
  0% {
    top: 100%;
  }
  100% {
    top: var(--foo);
  }
}

//Responsive

@media screen and (max-width: 600px) {
  .aboutUsModal {
    margin: 20px !important;
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    padding: 10px;
    word-break: break-word;
  }
  .mobileAboutLogin {
    width: 100% !important;
    background: none !important;
  }
  .outer-wrapper {
    width: 100%;
  }
  .mobileAboutUsLogoDisplay {
    text-align: center;
  }
}
