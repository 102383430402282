@import "./variables";

@media screen and (max-width: 1024px) {
  .tt-mobileView {
    display: none;
  }
}
.tt-userDetailContainer {
  position: absolute;
  bottom: 3px;
  width: 95.5%;
  margin-left: 10px;
  margin-right: 10px;
  background: $userDetailBgColor;
  //   margin-top: 4px;
  color: $whiteColor;
  padding: 10px 10px 0 10px;
  border-radius: 5px;
  transition: 0.3s;
  .tt-userDetailTitle {
    font-size: 16px;
    text-shadow: 1px 1px 2px $borderColor;
    span {
      font-weight: 500;
    }
  }
}

.tt-mobileUserDetailContainer {
  position: relative;
  width: 100%;
  background: $userDetailBgColor;
  color: $whiteColor;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 2px 3px 3px $shadowColor;
  .tt-mobileUserDetailTitle {
    font-size: 16px;
    text-shadow: 1px 1px 2px $borderColor;
    margin-bottom: 0;
    span {
      font-weight: 500;
    }
  }
}

.userDetailDisplay {
  //   top: -13em;
  opacity: 0;
}

.studentDetail {
  p {
    margin-bottom: 0;
  }
  span:last-child {
    span:last-child {
      display: none;
    }
  }
}

.userDetailChipBox {
  div:last-child {
    span:last-child {
      display: none;
    }
  }
}

.studentDetailContainer {
  top: -13em;
}

// .studentDetailHeight {
//   top: -14em;
// }
.studentNoticeHeight {
  top: -180px;
}
.staffNoticeHeight {
  //   top: -160px;
  top: -180px;
}

.studentPostContent {
  margin-top: -175px !important;
}

.staffPostContent {
  margin-top: -160px !important;
}

.userDetailHolder {
  width: max-content;
  display: inline-flex;
  //   background: blue;
  margin-right: 10px;
  border: 2px solid $whiteColor;
  border-radius: 20px;
  margin-bottom: 3px;
}
.userDetailYearHolder {
  background: $primaryBtnColor;
  display: inline-block;
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.userDetailEducationLevelHolder {
  background: $primaryBtnColor;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 11px;
}
.displayMoreChips {
  border-radius: 20px;
  text-align: center;
  padding-left: 3px;
  cursor: pointer;
}
.userDetailSectionHolder {
  display: inline-block;
  background: $primaryHoverColor;
  padding-left: 5px;
  padding-top: 2px;
  padding-right: 10px;
  font-size: 11px;
  line-height: 23px;
  padding-bottom: 0px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.userDetailChipBox {
  margin-bottom: 10px;
  display: flex;
  overflow-x: auto;
}

.studentDetail {
  div {
    p:last-child {
      margin-bottom: 3px;
    }
  }
}

//Responsive
.mobileChipBox {
  display: none !important;
}
.familyPageMobileButton {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .userDetailChipBox {
    margin-bottom: 5px;
  }
  .desktopChipBox {
    display: none !important;
  }
  .mobileChipBox {
    display: block !important;
  }
  .userDetailYearHolder {
    font-size: 12px;
  }
  .displayMoreChips {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 600px) {
  .familyPageMobileButton {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 414px) {
  .studentOtherDetail {
    font-size: 11px;
  }
}

@media screen and (max-width: 400px) {
  .userDetailHolder {
    margin-right: 8px;
  }
}

@media screen and (max-width: 393px) {
  .tt-mobileUserDetailContainer {
    .tt-mobileUserDetailTitle {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 320px) {
  .homeRoomTeacher-mobile {
    font-size: 12px !important;
  }
  .studentOtherDetail {
    font-size: 10px;
  }
}
