@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "./variables";

.fc-sat {
  color: $calendarHolidayColor;
  font-weight: bold;
}

.fc-content {
  cursor: pointer;
  color: $whiteColor;
}

.fc-event {
  font-size: 13px;
}

.fc-day-grid {
  font-size: 18px;
}

.fc-head {
  background-color: $primaryColor;
  color: $whiteColor;
}
.tt-calendar-dateNavigation {
  margin: 10px;
  .tt-calendarButton {
    margin-left: unset !important;
    top: -8px;
  }
  .tt-calendar-input {
    padding-left: 10px;
  }
  label {
    margin-bottom: 0;
  }
}

.fc-unthemed td.fc-today {
  padding: 0 !important;
}
.fc-widget-content.fc-today:before {
  content: "\00a0";
  border: 2px solid $primaryColor;
  height: 100%;
  width: calc(100% - 4px);
  display: block;
  position: relative;
  background: transparent;
}
.fc-today {
  background: none !important;
}
.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-mirror-skeleton td {
  padding-right: 5px !important;
}

.fc-row .fc-content-skeleton {
  padding-bottom: 0;
}

.tt-calendarError {
  color: $calendarHolidayColor;
  line-height: 0;
  position: relative;
  top: 14px;
}

.tt-displayDate {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 78vh;
  overflow-y: scroll;
}

.tt-calendarHolder {
  width: 42%;
  margin: 10px;
  cursor: pointer;
  height: 40% !important;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
}

.fc-dayGrid-view .fc-body .fc-row {
  min-height: 2em;
}

.fc-day-grid-container {
  height: auto !important;
  //   overflow-y: auto !important;
}

.tt-calendarBox {
  .fc-day-grid-container {
    height: 346px !important;
    overflow-y: auto !important;
  }
}

.tt-calendarLabel {
  text-align: right;
  line-height: 2;
  font-weight: bold;
  font-size: 14px;
}

.tt-calendaHeaderTitle {
  display: flex;
  justify-content: center;
  font-size: 18px;
}

.checkbox {
  margin: 10px 0 10px 21px;
}

//bring popover
a.fc-more {
  margin: 0;
  font-size: 14px;
}

.fc-more-cell {
  position: relative;
  background-color: $primaryColor !important;
  margin-left: 4px;
}

.fc-event-container {
  height: auto !important;
  padding: 5px;
}
.fc-unthemed .fc-popover {
  box-shadow: 2px 3px 3px $shadowColor;
}

//Responsive

@media screen and (max-width: 1024px) {
  .tt-calendarBox {
    .fc-day-grid-container {
      height: auto !important;
    }
  }
}

.tt-chipBox {
  display: flex;
  flex-wrap: wrap;
  .tt-chipHolder {
    background-color: $primaryColor;
    color: $whiteColor;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.33);
    margin-right: 8px;
    border-radius: 20px;
    padding: 3px 20px;
  }
}

.calendarEventColorBox {
  width: 50px;
  height: 25px;
  margin-left: auto;
  margin-right: auto;
}

.allCalendarDisplay {
  .fc {
    .fc-header-toolbar {
      .fc-center {
        h2 {
          font-size: 15px !important;
          font-weight: bold;
        }
      }
    }
    .fc-view-container {
      zoom: 45%;
      .fc-day-grid-event {
        height: 2px;
      }
      .fc-content {
        display: none;
      }
    }
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  // margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  // padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  // background: grey;
  margin-left: 30px;
  margin-bottom: 30px;
}
