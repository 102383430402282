// Colors
$primaryColor: #4878ae;
$selectedPrimaryColor: #4878ae40;
$primaryColorDark: #264a73;
$primaryHoverColor: #4878ae;
$gradientBg: linear-gradient(45deg, $primaryColor, $selectedPrimaryColor);

$shadowColor: rgba(0, 0, 0, 0.33);
$borderColor: black;
$whiteColor: white;

$contentBackgroundColor: #dce0e5;
$displayBackgroundColor: rgba($primaryColorDark, 0.9);
$calendarBorderColor: rgba(43, 103, 138, 0.3);
$displayBackgroundHoverColor: #4878aee6;
$tableAlternateBgColor: rgb(193, 216, 243);
$loginBackgroundColor: #eaeded;

$editButtonColor: rgba(0, 0, 0, 0.5);
$breadCrumbColor: rgba(0, 0, 0, 0.7);
$error404: #a7a7a7;
$calendarHolidayColor: #a70707;
$calendatEventColor: #1abc9c;
//ButtonColors

//primary
$primaryBtnColor: $primaryColorDark;
$primaryGradientColor: $primaryColor;
$primaryBtnHoverColor: $primaryColor;
//Secondary
$secondaryBtnColor: darkgreen;
$secondaryGradientColor: green;
$secondaryBtnHoverColor: darkgreen;
//Danger
$dangerBtnColor: #cc0000;
$dangerGradientColor: #c50909;
$dangerBtnHoverColor: #a10707;
//Warning
$warningBtnColor: yellow;
$warningGradientColor: #f1c40f;
$warningBtnHoverColor: #f1c40f;
//Cancel
$cancelBtnColor: #7f8c8d;
$cancelGradientColor: #707b7c;
$CancelBtnHoverColor: #616a6b;

//GeneralSetting
$ttGeneralYellow: #ffcc33;
$ttGeneralBlue: #144b63;
$ttGeneralGreen: #009901;
$ttGeneralRed: #ff0000;

//attachfilebox
$attachFileBoxColor: #feffcc;

//tooltip
$tooltipColor: #264a73;

//Admission
$searchForm: $primaryColorDark;
$nameSearch: #bacaeb;
$indexInfo: rgb(255, 204, 0);
$alphabetSearchHover: $primaryColor;

//Comment
$commentListColor: rgba(86, 61, 124, 0.1);

//permission
$permissionLabel: #4878ae;
$permBgColor: #e1eeff;

//userDetail
$userDetailBgColor: rgba($primaryColor, 1);

$flashHoverColor: rgba(0, 0, 0, 0.7);

//mcq
$mcqCorrect: #90ee90;
$mcqWrong: #ffa07a;

//indicate-optional
$optional: #6f6f6fc2;
